import {Box, Modal} from "@mui/material";
import * as React from "react";


const IframeModal = (props: {src: string|null}) => {
  const {
    src
  } = props;

  return (
    <Modal
      open={!!src}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Box>
        <Box
          component={'iframe'}
          title={'idea-view'}
          src={`${src}`}
          sx={{
            width: '100vw',
            overflowX: 'hidden',
            minHeight: '100vh',
            border: 'none'
          }}
        />
      </Box>
    </Modal>
  );
}

export default IframeModal;