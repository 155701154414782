import NewsIcon from "../../assets/news.png";
import * as React from "react";
import Posts from "../Posts";
import EmptySearchResults from "../EmptySearchResults/EmptySearchResults";
import {useEffect, useState} from "react";
import {PostInterface} from "../../types";
import axios from "axios";
import PostsList from "../PostsList";
import {Box, Typography} from "@mui/material";
import Loading from "../Loading";

export interface ArticlesSearchResultsProps{
  query: string;
}

const preparePosts = (data: Array<any>): Array<PostInterface> => {
  return data.map((item) => {
    return {
      id: item.id,
      title: item.title.rendered,
      image: item.image,
      link: item.link,
      thumbnail: item.thumbnail,
      modified: item.modified,
      published_at: item.date,
      slug: item.slug,
      singlePath: item.link
    };
  })
}
export default function ArticlesSearchResults(props: ArticlesSearchResultsProps){
  const {
    query
  } = props;
  const [posts, setPosts] = useState<Array<PostInterface>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    setPosts([]);
    axios.get(`https://ramzarz.news/wp-json/wp/v2/posts?categories=814&search=${query}&per_page=30&page=1`)
      .then(response => response.data)
      .then((data) => {
        setPosts(preparePosts(data))
      }).catch(function (err) {
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [query]);

  return (
    <>
      {posts.length > 0 && (
        <Box px={2}>
          <Typography fontWeight={'bold'}>مقالات یافت شده</Typography>
          <PostsList type={'article'} posts={posts} singlePath="/news/:id/:slug" />
        </Box>
      )}
      {posts.length === 0 && !loading && (
        <EmptySearchResults />
      )}
      {
        loading && (
          <Box pt={3}>
            <Loading/>
          </Box>
        )
      }
    </>
  );
}