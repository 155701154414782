import {ItemInterface} from "./types";

export const transformItems = (data: any): ItemInterface[] => {
  if (!data || !data.data || !data.data.cryptoCurrencyList) {
    return [];
  }
  return data.data.cryptoCurrencyList.map((d: any) => {
    let p = d.split('/');
    return {
      id: p[0],
      name: p[1],
      symbol: p[2],
      price_usd: p[3],
      price_toman: p[4],
      icon: `https://app.ramzarz.news/coins/32x32/${p[0]}.png`,
      decimals: 6
    };
  })
}

export default {};