import moment from "jalali-moment";

export default function PersianDate(props: { date: string; showDate?: boolean }) {
  if (!props.date) {
    return <span></span>;
  }

  return (
    <span className="iransans-fa-num">
      {props.showDate
        ? moment(props.date).locale('fa').fromNow()
        : moment(props.date, 'YYYY/MM/DD').locale('fa').format('DD MMMM YYYY')}
    </span>
  );
}