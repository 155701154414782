import {useLocalStorage} from 'usehooks-ts';

const useWatchListCoins = () => {
  const [watchlistCoins, setWatchlistCoins] = useLocalStorage<Array<number>>('watchlist_coins', []);

  const addToWatchList = (id: number) => {
    const currentIds = watchlistCoins;
    // @ts-ignore
    if(!currentIds.includes(id)){
      // @ts-ignore
      setWatchlistCoins([...currentIds, id]);
    }
  }

  const removeFromWatchList = (id: number) => {
    const currentIds = watchlistCoins;
    setWatchlistCoins(
      currentIds.filter((oldId: number) => oldId != id)
    );
  }

  const isWatchListed = (id: number) => {
    // @ts-ignore
    return watchlistCoins.includes(id);
  }

  return {ids: watchlistCoins, addToWatchList, removeFromWatchList, isWatchListed};
}

export default useWatchListCoins;