import {Box, Typography} from "@mui/material";
import EmptySearchResultsSvg from '../../assets/empty-search-results.svg';

const DEFAULT_MESSAGE = 'هیچ نتیجه ای یافت نشد!';

export default function EmptySearchResults(props: {message?: string}){
  const {
    message
  } = props;
  return (
    <Box
      sx={{
        textAlign: 'center',
        direction: 'rtl'
      }}
    >
      <img src={EmptySearchResultsSvg} alt="empty results"/>
      <Typography>{!!message ? message : DEFAULT_MESSAGE}</Typography>
    </Box>
  );
}