import * as React from "react";
import {useEffect, useState} from "react";
import EmptySearchResults from "../EmptySearchResults/EmptySearchResults";
import {PostInterface} from "../../types";
import axios from "axios";
import PostsList from "../PostsList";
import {Box, Typography} from "@mui/material";
import Loading from "../Loading";

export interface IdeasSearchResultsProps{
  query: string;
}

const preparePosts = (data: Array<any>): Array<PostInterface> => {
  return data.map((item) => {
    return {
      id: item.id,
      title: item.title,
      image: item._embedded.self[0].yoast_head_json.og_image[0].url,
      link: item.url,
      thumbnail: item._embedded.self[0].yoast_head_json.og_image[0].url,
      modified: item._embedded.self[0].yoast_head_json.article_modified_time ?? item._embedded.self[0].date,
      published_at: item._embedded.self[0].date,
      slug: item._embedded.self[0].slug,
      singlePath: item.url
    };
  })
}
export default function IdeasSearchResults(props: IdeasSearchResultsProps){
  const {
    query
  } = props;
  const [posts, setPosts] = useState<Array<PostInterface>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    setPosts([]);
    axios.get(`https://ramzarz.news/ideas/wp-json/wp/v2/search/?per_page=30&page=1&search=${query}&_embed=true`)
      .then(response => response.data)
      .then((data) => {
        setPosts(preparePosts(data))
      }).catch(function (err) {
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }, [query]);

  return (
    <>
      {posts.length > 0 && (
        <Box px={2}>
          <Typography fontWeight={'bold'}>تحلیل‌های یافت شده</Typography>
          <PostsList type={'news'} posts={posts} singlePath="/news/:id/:slug" />
        </Box>
      )}
      {posts.length === 0 && !loading && (
        <EmptySearchResults />
      )}
      {
        loading && (
          <Box pt={3}>
            <Loading/>
          </Box>
        )
      }
    </>
  );
}