import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const useIframeModal = () => {
  const [modalSrc, setModalSrc] = useState<string|null>(null);
  const [slug, setSlug] = useState<string|null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(!!slug){
      if(String(slug).startsWith('i-')){
        setModalSrc(`https://ramzarz.news/ideas/${String(slug).slice(2)}?app_user=1`)
      } else if(String(slug).startsWith('c-')){
        setModalSrc(`https://ramzarz.news/ideas/coins/${String(slug).slice(2)}?app_user=1`)
      } else if(String(slug).startsWith('a-')){
        setModalSrc(`https://ramzarz.news/${String(slug).slice(2)}?app_user=1`)
      } else if(String(slug).startsWith('n-')){
        setModalSrc(`https://ramzarz.news/news/${String(slug).slice(2)}?app_user=1`)
      }
    }else{
      setModalSrc(null)
    }
  }, [slug]);

  const open = (s: string) => {
    const currentPos = document.documentElement.scrollTop || document.body.scrollTop;
    if(currentPos < 5){
      window.scrollTo(0, 5)
    }

    navigate(`${s}`);
  }

  return {
    src: modalSrc,
    open,
    setSlug
  };
}

export default useIframeModal;