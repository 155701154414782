import {Box, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import PersianDate from "../PersianDate";
import * as React from "react";
import {PostInterface} from "../../types";
import useIframeModal from "../../hooks/use-iframe-modal";
import LinkButton from "../LinkButton";
import {useEffect, useState} from "react";

const PostItem = (props: { post: PostInterface, singlePath: string, type: 'article' | 'news' }) => {
  const {type, post} = props;

  const {open} = useIframeModal();
  const [path, setPath] = useState('');

  useEffect(() => {
    if(type === 'news'){
      setPath(`/blog/n-${post.id}`)
    } else if( type === 'article'){
      setPath(`/articles/a-${post.slug}`)
    }
  }, [type, post])

  return (
    <Paper className={'post-paper'} elevation={0}>
      <Grid container sx={{py: 1}}>
        <Grid item xs={3} className={'post-media'}>
          <LinkButton
            onClick={() => open(path)}
          >
            <Box component='img'
                 className={'post-img'}
                 src={props.post.thumbnail}
                 sx={{
                   width: '64px',
                   height: '70px',
                   maxHeight: '70px',
                   borderRadius: '3px'
                 }}
            />
          </LinkButton>
        </Grid>
        <Grid item xs={9} className={'post-title'}>
          <Stack>
            <Box className={'post-title'}>
              <LinkButton
                onClick={() => open(path)}
              >
                <Typography variant='h6' fontWeight='700' color='text.primary'>{props.post.title}</Typography>
              </LinkButton>
            </Box>
            <Box className={'post-date'}>
              <Typography fontSize={'0.5rem'} color='text.secondary'>
                <PersianDate 
                date={props.post.modified}
                showDate={true}
                /></Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Divider/>
    </Paper>
  );
}

export default PostItem;