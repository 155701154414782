import * as React from "react";
import { Box, Skeleton } from "@mui/material";
import { useState } from "react";


interface MultiSrcImgProps {
  src: string;
  fallbackSrc?: string;
  defaultSrc?: string;
  size: string;
}

export default function MultiSrcImg(props: MultiSrcImgProps) {
  const {
    src,
    fallbackSrc,
    defaultSrc,
    size
  } = props;

  const [isLoadedImage, setIsLoadedImage] = useState(false);

  const handleImageLoad = () => {
    setIsLoadedImage(true);
  };
  return (
    <>
      
      {!isLoadedImage && (
        <Skeleton
          animation="wave"
          variant="circular"
          sx={{
            width: size,
            height: size
          }}
        />
      )}
      <Box
        component="img"
        src={src}
        onError={(event) => {
          event.currentTarget.onerror = () => {
            event.currentTarget.onerror = null;
            event.currentTarget.src = fallbackSrc ?? defaultSrc!;
          }
          event.currentTarget.src = fallbackSrc ?? defaultSrc!;
        }}
        onLoad={handleImageLoad}
        sx={{
          paddingRight: '0px !important',
          width: size,
          height: size,
          display: isLoadedImage ? "block" : "none",
          borderRadius: '50%'
        }}
      />
    </>
  );
}

