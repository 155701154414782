import { Avatar, Box, Link, Stack, Typography, useMediaQuery, useTheme, Skeleton, CircularProgress } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import api_url, { number_format } from "../../tools/helpers";
import MultiSrcImg from "../MultiSrcImg";

interface ExchangerInterface {
  name_fa: string,
  name_en: string,
  logo: string,
  link: string,
  type: string,
  percent: string,
}

interface RowInterface {
  id: number,
  exchanger: ExchangerInterface,
  buy: number,
  sell: number,
}

const StyledTableCell = styled(TableCell)(({ theme }) => {
  const isXS = useMediaQuery(theme.breakpoints.down('md'));
  const xsStyles = {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontSize: 11,
      paddingLeft: '3px!important',
      borderRadius: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      paddingLeft: '3px!important',
      paddingRight: '3px!important',
    },
  };
  const normalStyles = {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }
  return isXS ? xsStyles : normalStyles;
});

const StyledTableRow = styled(TableRow)(({ theme }) => {
  const isXS = useMediaQuery(theme.breakpoints.down('md'));
  const normalStyles = {
    '&': {
      borderBottomWidth: '2px',
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  };

  if (isXS) {
    Object.assign(normalStyles, {
      borderRadius: '0px!important',
    })
  }

  return normalStyles;
});

function transformData(data: any[]): RowInterface[] {
  return data.map((d: any) => ({
    id: d.id,
    buy: d.buy,
    sell: d.sell,
    exchanger: {
      name_fa: d.exchanger.name_fa,
      name_en: d.exchanger.name_en,
      link: d.exchanger.link,
      logo: d.exchanger.logo,
      type: d.exchanger.type,
      percent: d.exchanger.percent,
    }
  }))
}

export default function Exchanges(props: { currency?: string | undefined, onLoading?: (loading: boolean) => unknown }) {

  const { currency } = useParams();
  const { onLoading } = props;
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('md'));
  const [token, setToken] = useState(1);

  useEffect(() => {
    let q = currency !== undefined ? currency : 'bitcoion';
    let url = api_url(`/v1/prices/${q}?t=${token}`);
    setLoading(true);
    axios.get(url).then(response => response.data)
      .then((data) => {
        setRows(transformData(data.data))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [currency, token])
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      setToken(token + 1)
    }, 60000)
    return () => clearInterval(interval);
  }, [token])
  useEffect(() => {
    if (!!onLoading) {
      onLoading(loading || props.currency === undefined);
    }
  }, [loading, onLoading, props.currency])



  return (
    <Box className={'ran-exchanges-table2'}>
      <TableContainer component={Paper} elevation={isXS ? 0 : 1}>
        <Table aria-label="exchanges table" size={isXS ? "small" : 'medium'}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell className={'ran-exchanger-name-head2'} align="right">صرافی</StyledTableCell>
              <StyledTableCell className={'ran-exchanger-buy-head2'} align="center">فروش به کاربر(تومان)</StyledTableCell>
              <StyledTableCell className={'ran-exchanger-sell-head2'} align="center">خرید از
                کاربر(تومان)</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                key={row.id}
                hover
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell align="right" component="th" scope="row" className={'ran-exchanger-name'}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>

                    <MultiSrcImg
                      src={row.exchanger.logo}
                      defaultSrc='default.jpg'
                      size='28px'
                    />


                    <Link sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none' }, color: 'inherit' }} underline={'none'}
                      href={row.exchanger.link} target='_blank'><Typography
                        sx={{ mx: 1 }}>{row.exchanger.name_fa}</Typography></Link>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center" className={'iransans-fa-num ran-buy'}
                  sx={{ fontWeight: '300' }}>{number_format(row.buy)}</StyledTableCell>
                <StyledTableCell align="center" className={'iransans-fa-num ran-sell'}
                  sx={{ fontWeight: '300' }}>{number_format(row.sell)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}