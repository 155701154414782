import {Button, ButtonProps, styled} from "@mui/material";
import * as React from "react";

const LinkButton = styled((props: ButtonProps) => (
  <Button
    disableRipple
    disableTouchRipple
    disableFocusRipple
    disableElevation
    variant={'text'}
    {...props}
  />
))<ButtonProps>(({theme}) => ({
  padding: 0,
  display: 'inline-block',
  '&:hover': {
    backgroundColor: 'unset'
  }
}));

export default LinkButton;