export function number_format(n: string | number): string {
  return parseInt(n + '') > 1 ? new Intl.NumberFormat('en-US').format(parseInt(n + '')) : parseFloat(n + '').toFixed(6)
}

export default function api_url(path = '') {

  let baseUrl = process.env.NODE_ENV === 'production' ?
    'https://app.ramzarz.news/node/api/' :
    'https://app.ramzarz.news/node/api/';

  return baseUrl + path.replace(/^\/+/g, '');
}

export function onRender(id: any, phase: any, actualDuration: any, baseDuration: any, startTime: any, commitTime: any) {
  
}
