import * as React from "react";
import Posts from "../Posts";
import {Box} from "@mui/material";
import NewsIcon from "../Icons/NewsIcon";

const Icon = () => {
  return (
    <Box component={'span'} pl={0.5}>
      <NewsIcon sx={{width: '24px', height: '24px'}} />
    </Box>
  );
}

export default function NewsList() {
  return (
    <Posts
      url="https://app.ramzarz.news/node/api/v1/news?per_page=20"
      title="جدیدترین اخبار"
      singlePath="/news/:id/:slug"
      icon={<Icon />}
      type={'news'}
    ></Posts>
  );
}
