import {Box} from "@mui/material";

const SingleIdea = () => {
  return (
    <div>
      <Box
        component={'iframe'}
        title={'foobar'}
        src={'https://ramzarz.news/what-is-kekius-maximus/'}
        sx={{
          width: '100vw',
          height: '100vh'
        }}
      />
    </div>
  );
}

export default SingleIdea;