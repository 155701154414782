import {Divider, Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import SortableButton from "./SortableButton";

export default function CurrencyHead(props: {
  sort: 'cmcRank' | 'price' | 'percentChange24h',
  order: 'asc' | 'desc',
  onSortChange: (newVal: 'cmcRank' | 'price' | 'percentChange24h') => void,
  onOrderChange: (newVal: 'asc' | 'desc') => void,
  buyButtonStatus: 0 | 1
}) {
  const {
    sort,
    order,
    onSortChange,
    onOrderChange,
    buyButtonStatus
  } = props;
  const matches = useMediaQuery('(max-width:560px)');
  return (
    <Paper elevation={0} sx={{backgroundColor: 'primary.light', borderRadius: 0}}>
      <Grid container py={1}>
        <Grid item xs={1.5} sm={1} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'#'}
            value={'cmcRank'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={3.5} sm={buyButtonStatus === 1 ? 3.5 : 4} sx={{textAlign: 'left',pl: matches ? '16px' : '32px', my: 'auto', fontSize: '0.75rem'}}>ارز</Grid>
        <Grid item xs={buyButtonStatus === 1 ? 3:4} sm={buyButtonStatus === 1 ? 4.5 : 5} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'قیمت'}
            value={'price'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
        <Grid item xs={buyButtonStatus === 1 ? 2 : 3} sm={buyButtonStatus === 1 ? 1.5 : 2} sx={{textAlign: 'center', my: 'auto'}}>
          <SortableButton
            name={'تغییرات'}
            value={'percentChange24h'}
            sort={sort}
            order={order}
            onSortChange={onSortChange}
            onOrderChange={onOrderChange}
          />
        </Grid>
        {buyButtonStatus === 1 &&
            <Grid item xs={1.8} sm={1.5} sx={{ textAlign: 'center', my: 'auto' }}>
              <Typography fontSize={"0.63rem"}  sx={{marginLeft:1}}  noWrap>
                خرید
              </Typography>
            </Grid>
        }
      </Grid>
      <Divider/>
    </Paper>
  );
}