import { Box, Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import Ellipsis from "../Ellipsis";
import * as React from "react";
import { Currency } from "./types";
import Toman from "../Toman";
import DollarPrice from "../DollarPrice";
import Blinker from "../Blinker";
import Percentage from "../Percentage";
import { memo } from "react";
import { Link } from "react-router-dom";

const IconAndNameCol = memo(function IconAndNameCol(props: {
  name: string,
  slug: string,
  cmcId: number,
  symbol: string,
  buyButtonStatus: 0 | 1
}) {
  const {
    name,
    slug,
    cmcId,
    symbol,
    buyButtonStatus
  } = props;
  return (
    <Grid item xs={3.5} sm={buyButtonStatus === 1 ? 3 : 4} sx={{ textAlign: 'center' }} justifyContent="center">
      <Stack direction='row'>
        <Box sx={{ my: 'auto' }} className={'currency-logo'}>
          <Box sx={{
            padding: 0,
            justifyContent: 'flex-start',
            display: 'inline',
            '&:hover': { backgroundColor: 'inherit' }
          }} >
            <img
              src={`/coins/32x32/${cmcId}.png`}
              onError={(event) => {
                event.currentTarget.onerror = () => {
                  event.currentTarget.onerror = null;
                  event.currentTarget.src = 'default.jpg';
                }
                event.currentTarget.src = `https://s2.coinmarketcap.com/static/img/coins/32x32/${cmcId}.png`;
              }}
            />
          </Box>
        </Box>
        <Stack sx={{ textAlign: 'left', ml: 1 }}>
          <Box>
            <Button sx={{
              padding: 0,
              justifyContent: 'flex-start',
              textTransform: 'capitalize',
              display: 'inline',
              '&:hover': { backgroundColor: 'inherit' }
            }} component={'span'} disableElevation disableFocusRipple disableRipple variant={'text'}
              className={'currency-detail-link'} href={`/currencies/${slug.toLowerCase()}`}>
              <Ellipsis text={name} />
            </Button>
          </Box>
          <Box>
            <Box sx={{
              padding: 0,
              justifyContent: 'flex-start',
              display: 'inline',
              '&:hover': { backgroundColor: 'inherit' }
            }}
              className={'currency-symbol-link'} >
              <Typography
                className={'currency-symbol1'}
                sx={{
                  fontSize: '15px',
                  fontWeight: '800'
                }}
                color={(theme) => theme.palette.text.primary}>{symbol.toUpperCase()}</Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Grid>
  );
});

const PriceCol = memo(function PriceCol(props: {
  raise: -1 | 0 | 1 | undefined
  price: string,
  price_toman: string,
  buyButtonStatus: 0 | 1
}) {
  const {
    raise,
    price,
    price_toman,
    buyButtonStatus
  } = props;
  return (
    <Grid item className={'currency-price'} xs={buyButtonStatus === 1 ? 4 : 5} sm={buyButtonStatus === 1 ? 5.5 : 5}
      sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box className={'dollar-price'}>
        <Blinker mode={raise}>
          <DollarPrice price={price} />
        </Blinker>
      </Box>
      <Box className={'toman-price'} sx={{ direction: 'rtl', color: 'grey' }}>
        <Toman
          irt={price_toman}
          usd={price}
          symbol='تومان'
        />
      </Box>
    </Grid>
  );
})

const PercentageCol = memo(function PercentageCol(props: {
  percentChange24h: string
}) {
  const {
    percentChange24h
  } = props;
  return (
    <Grid item className={'changes-24h'} xs={2} sm={1} sx={{ textAlign: 'center', my: '0' }}>
      <Percentage
        value={percentChange24h} compact />
    </Grid>
  );
})

const RankCol = memo(function RankCol(props: {
  cmcRank: number,
  buyButtonStatus: 0 | 1
}) {
  const {
    cmcRank,
    buyButtonStatus
  } = props;
  return (
    <Grid item xs={buyButtonStatus === 1 ? 1 : 1.5} sm={1} sx={{ textAlign: 'center', my: '0', display: 'grid', justifyContent: 'center', alignItems: 'center' }} className={'rank'}>
      <Typography variant='body1'>{cmcRank}</Typography>
    </Grid>
  );
})

const ActionButtonCol = memo(function ActionButtonCol(props: {
  slug: string
}) {
  const { slug } = props;
  return (
    <Grid item xs={1} sm={1.5} sx={{ textAlign: 'center', my: 'auto' }} className={'action-button'}>
      <Box
        sx={{
          padding: 0,
          justifyContent: 'center',
          marginLeft: 1,
          display: 'inline',
          '&:hover': { backgroundColor: 'inherit' }
        }}>
        <svg width="20" height="20" viewBox="0 0 24 24" fill="#23b271" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.6833 13.7674C10.6833 13.4014 10.3865 13.1046 10.0205 13.1046C9.65443 13.1046 9.35768 13.4014 9.35768 13.7674V16.9046C9.35768 17.2707 9.65443 17.5674 10.0205 17.5674C10.3865 17.5674 10.6833 17.2707 10.6833 16.9046V13.7674Z"
            fill="#23b271" />
          <path
            d="M14.0857 13.1046C14.4517 13.1046 14.7485 13.4014 14.7485 13.7674V16.9046C14.7485 17.2707 14.4517 17.5674 14.0857 17.5674C13.7196 17.5674 13.4229 17.2707 13.4229 16.9046V13.7674C13.4229 13.4014 13.7196 13.1046 14.0857 13.1046Z"
            fill="#23b271" />
          <path fillRule="evenodd" clipRule="evenodd"
            d="M15.2883 2.69477C15.0298 2.43558 14.6102 2.435 14.351 2.69348C14.0918 2.95196 14.0912 3.37161 14.3497 3.63081L16.6149 5.9023H7.38495L9.65019 3.63081C9.90867 3.37161 9.90809 2.95196 9.6489 2.69348C9.3897 2.435 8.97005 2.43558 8.71157 2.69477L5.51287 5.9023H5.12465C4.60152 5.9023 3.91121 5.92073 3.36727 6.28505C2.75202 6.69713 2.5 7.4063 2.5 8.33253C2.5 9.3429 2.72814 10.1128 3.40515 10.503C3.56439 10.5948 3.73018 10.6533 3.89173 10.691L5.08056 17.9758C5.22843 18.8722 5.49768 19.7838 6.16651 20.4612C6.85403 21.1574 7.85384 21.5 9.22522 21.5H14.5541C16.0113 21.5 17.0169 21.1825 17.6814 20.4862C18.3175 19.8196 18.5217 18.9149 18.678 18.0986L20.0982 10.6934C20.2629 10.6557 20.4323 10.5967 20.5948 10.503C21.2719 10.1128 21.5 9.3429 21.5 8.33253C21.5 7.4063 21.248 6.69713 20.6327 6.28505C20.0888 5.92073 19.3985 5.9023 18.8753 5.9023H18.487L15.2883 2.69477ZM18.7352 10.7628H5.24656L6.38847 17.76C6.5234 18.5777 6.73587 19.1512 7.10974 19.5298C7.46501 19.8896 8.06916 20.1744 9.22522 20.1744H14.5541C15.8451 20.1744 16.4171 19.891 16.7224 19.571C17.0561 19.2213 17.2143 18.6945 17.3761 17.8493L18.7352 10.7628ZM4.10494 7.38641C4.01101 7.44933 3.82558 7.62388 3.82558 8.33253C3.82558 9.21569 4.03186 9.33427 4.06633 9.35408C4.11794 9.38383 4.21454 9.41576 4.40812 9.42999C4.55571 9.44084 4.70779 9.43969 4.89669 9.43828C4.96702 9.43775 5.04245 9.43718 5.12465 9.43718H18.8753C18.9576 9.43718 19.033 9.43775 19.1033 9.43828C19.2922 9.43969 19.4443 9.44084 19.5919 9.42999C19.7855 9.41576 19.8813 9.38425 19.9329 9.3545C19.9674 9.33469 20.1744 9.21569 20.1744 8.33253C20.1744 7.62388 19.989 7.44933 19.8951 7.38641C19.7298 7.27574 19.4392 7.22788 18.8753 7.22788H5.12465C4.56081 7.22788 4.27018 7.27574 4.10494 7.38641Z"
            fill="#23b271" />
        </svg>
      </Box>
    </Grid>
  );
});

export default function CurrencyRow(props: { currency: Currency, buyButtonStatus: 0 | 1 }) {
  const {
    currency,
    buyButtonStatus
  } = props;
  return (
    <Paper elevation={0} className={'currency-row'}>
      <Button sx={{
        padding: 0,
        justifyContent: 'flex-start',
        display: 'inline',
        '&:hover': { backgroundColor: 'inherit', color: 'inherit', textDecoration: 'none' },
        '&:focus': { backgroundColor: 'inherit', color: 'inherit', textDecoration: 'none' }
      }} component={'a'} disableElevation disableFocusRipple disableRipple variant={'text'}
        href={`/currencies/${currency.slug.toLowerCase()}`}>
        <Grid container sx={{ pt: '10px', pb: '8px', mt: '0!important', mb: '0!important', height: '60px!important' }}>
          <RankCol buyButtonStatus={buyButtonStatus} cmcRank={currency.cmcRank} />
          <IconAndNameCol
            buyButtonStatus={buyButtonStatus}
            name={currency.name}
            symbol={currency.symbol}
            cmcId={currency.cmcId}
            slug={currency.slug}
          />
          <PriceCol
            buyButtonStatus={buyButtonStatus}
            raise={currency.raise}
            price={currency.price}
            price_toman={currency.price_toman}
          />
          <PercentageCol percentChange24h={currency.percentChange24h} />
          {buyButtonStatus === 1 && <ActionButtonCol slug={currency.slug} />}
        </Grid>
      </Button>
      <Divider />
    </Paper>
  );
}
